<!-- <template>
	<div class="card card-custom card-stretch gutter-b bg-danger">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder text-dark">TODO: {{props }}</h3>
		</div>
		<div class="card-body pt-2">
			{{props}}
		</div>
	</div>
</template> -->

<template>
	<div class="card card-custom card-stretch gutter-b">
        <div v-if="!loaded && customPeriod===true" class="d-flex justify-content-center align-items-center fading-background" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(255, 255, 255, 0.7);">
            <h4 style="font-weight: bold;">This may take a few minutes for longer reporting intervals...</h4>
        </div>
		<div class="card-header border-0 align-items-center">
            <h3 class="card-title font-weight-bolder text-dark">
                {{ device.properties && device.properties.label ? device.properties.label : name}} - {{ graphName }}
				<!-- <a
					v-if="['admin', 'noc', 'staff'].includes(currentUser.role)"
					target="_blank"
					:href="`https://orionweb2017.itcglobal.com/Orion/Interfaces/InterfaceDetails.aspx?NetObject=I:${interfaceID}&view=InterfaceDetails`"
					v-b-tooltip="'Open in Solarwinds'"
				>
					<img class="mx-5" width="20" :src="'/media/logos/solarwinds.png'" />
				</a> -->
                <!-- <b-button v-on:click="getStarlinkMetrics">TEST</b-button> -->
			</h3>
			<div class="ml-3">
                <b-tooltip target="liveButton" placement="top" triggers="hover">
                    Click to load live API data from the last 24 hours - Auto-updates graphs.
                </b-tooltip>
                <b-button id="liveButton" :disabled="liveDisabled"  :class="{ 'disabled-button': liveDisabled }" class="mr-2" variant="outline-primary" size="sm" @click="getStarlinkAPIData('liveButton')">
                    <i class="fas fa-circle text-danger"></i> Live
                </b-button>
                <b-tooltip v-if="!syncDisabled" target="syncButton" placement="top" triggers="hover">
                    Click to synchronize data with calendar selection.
                </b-tooltip>
                <b-button id="syncButton" :disabled="syncDisabled" :class="{ 'disabled-button': syncDisabled }" class="mr-2" variant="outline-primary" size="sm" @click="loadSelectedDataInterval"><i class="fas fa-sync"></i>
                    <i class="far fa-calendar-alt"></i>
                </b-button>
                <b-tooltip target="downloadButton" placement="top" triggers="hover">
                    Download Data from Graph to CSV file.
                </b-tooltip>
                <b-button id="downloadButton" variant="outline-primary" size="sm" @click="generateCSV">Download CSV</b-button>
            </div>
		</div>
		<div class="card-body pt-2" style="position: relative;">
            <div v-if="!loaded" class="d-flex justify-content-center" style="align-items:center; position: absolute;
                top: -10%;
                left: 0;
                width: 100%;
                height: 100%;"> 
                <b-spinner class="m-5" label="Busy"></b-spinner>
            </div>

			<div v-if="loaded === true && dyData.length === 0">
				<h4>
					<i
						class="fa fa-exclamation-circle fa-circle"
						aria-hidden="true"
						style="
							color: red;
							background-image: radial-gradient(at center, white 40%, transparent 40%);
						"
					></i>
					This Starlink has no data available at this time.
				</h4>
			</div>
			<dygraphs v-if="dyData.length > 0" :data="dyData" :options="dyOpts" />
		</div>
	</div>
</template>

<script>
// import { beforeRouteLeave } from 'vue-router';
import { KMG, formatTime, downloadCSV } from '@/helpers';
import { mapGetters } from 'vuex';
import Dygraph from 'dygraphs';

export default {
	name: 'StarlinkGraphs',
    components: {
        dygraphs: () => import('@/view/content/lib/dygraphs.vue'),
    },
    // beforeRouteLeave(to, from, next) {
    //     const itemsToKeep = ['user', 'userAuth']; // Specify the items you want to keep

    //     // Get all keys from local storage
    //     const keys = Object.keys(localStorage);
    //     console.log('store keys', keys)
    //     // Remove items that are not in the 'itemsToKeep' array
    //     keys.forEach(key => {
    //         if (!itemsToKeep.includes(key)) {
    //         localStorage.removeItem(key);
    //         }
    //     });
    //     console.log("NEXT!!!!!!!!!!!!")
    //     next();
    // },
	props: {
        siteid: { Type: Number, required: true},
        name: { Type: String, required: true },
		id: { type: Number, required: true },
		properties: { type: Object, require: false },
        device: { type: Object, require: false },
        widgetId: { type: Number, required: true}
	},
	data() {
		return {
            metricParam: 'metric',//single or multiple metrics: backend takes 'metric' vs 'metrics' to determine which api to hit.
            metrics: '',
            source: '',
            last_timestamp: '',
            pageLoadTime: 0,
            end: '',
            dates: {
                start: '',
                end: ''
            },
            consolidatedData: [],
            starlinkMetrics: [],
            errors: [],
            dyData: [],
			dyOpts: {},
			showDownload: false,
			loaded: false,
            graphName: '',
            liveDisabled: true,
            syncDisabled: true,
            customPeriod: false,
		};
	},
    computed: {
		...mapGetters(['currentUser', 'DateRange']),
	},
    methods: {
        generateCSV() {
			let columns = [];
			let csvData = {};

				this.starlinkMetrics.forEach((col) => {
                    let label = col.metric;
                    if(col.metric.match(/(uplinkThroughput)|(downlinkThroughput)/) !== null) {
                        label = `${col.metric} - Mbps`
                    } else
                    if(col.metric.match(/(pingLatency)/) !== null) {
                        label = `${col.metric} - ms`
                    } else
                    if(col.metric.match(/(pingDropRate)|(signalQuality)|(obstruction)/) !== null) {
                        label = `${col.metric} - percent`
                    } else
                    if(col.metric.match(/(uptime)/) !== null) {
                        label = `${col.metric} - s`
                    }

					columns.push(label);
					col.data.forEach((row) => {
						if (csvData[row['timestamp']] == undefined) csvData[row['timestamp']] = {};
						if (label === 'MODCOD') {
							if (MODCODS[row[1]] !== undefined) {
								csvData[row[0]][label] = MODCODS[row[1]].name;
							}
						} else {
							csvData[row['timestamp']][label] = row['value'];
						}
					});
				});

			let csv = 'Timestamp,' + columns.join(',') + '\n';
			for (let timestamp in csvData) {
				let timestring = new Date(timestamp).toISOString().split('T');
				csv += timestring[0] + ' ' + timestring[1].substring(0, 8) + ',';
				columns.forEach((col) => {
					csv +=
						csvData[timestamp][col] !== undefined && csvData[timestamp][col] !== null
							? csvData[timestamp][col] + ','
							: ',';
				});
				csv = csv.substring(0, csv.length - 1);
				csv += '\n';
			}
			return downloadCSV(
				csv,
				this.name + ` - Starlink ${this.graphName} - ` + new Date().toISOString().split('T')[0] + '.csv'
			);
		},
        loadSelectedDataInterval() {
            this.loaded = false;
            this.customPeriod = true;
            this.syncDisabled = true;

            this.$http.get(`site/${this.siteid}/starlink/metrics/${this.source}?${this.metricParam}=${this.metrics}&custom=report&from=${this.DateRange.start}&to=${this.DateRange.end}`)
                .then((res) => {
                    this.stopTimer();
                    this.starlinkMetrics = res.data;
                    this.setLastTimeStamp(res.data)
                    const CountOfMetrics = res.data.length;
                    this.processNewAPIData(CountOfMetrics); 
                    this.liveDisabled = false;
                    this.loaded = true;
                    this.customPeriod = false;
                })
                .catch(err => {
                    this.loaded = true;
                    this.customPeriod = false;
                    this.syncDisabled = false;
                    this.errors.push(err);
                    console.log("Error(Starlink API Data): ", this.errors);                                                                            
                })
        },
        // handleBeforeUnload(event) {
        //     console.log('EVENT:', event)
        //     if (!this.isRefreshing) {
        //         const itemsToKeep = ['user', 'userAuth'];

        //         // Get all keys from local storage
        //         const keys = Object.keys(localStorage);

        //         // Remove items that are not in the 'itemsToKeep' array
        //         keys.forEach(key => {
        //             if (!itemsToKeep.includes(key)) {
        //                 localStorage.removeItem(key);
        //             }
        //         })
        //         // localStorage.clear();
        //         // Or perform any other cleanup tasks here
        //     }
        // // The browser-specific returnValue is necessary to trigger the confirmation dialog
        // event.returnValue = '';
        // },
        // detectRefresh() {
        //     this.isRefreshing = true;
        // },
        updateStarlinkMetricData() {
            this.loaded = false;
            this.$http.get(`site/${this.siteid}/starlink/metrics/${this.source}?${this.metricParam}=${this.metrics}&from=${this.last_timestamp}`).then((res) => {
                let newData = false;
                const CountOfMetrics = res.data.length;
                if(res.data && CountOfMetrics>1) {
                    for(let i=0; i<CountOfMetrics; i++) {
                        const metricData = res.data[i].data;
                        const new_data_index = metricData.findIndex(item => item.timestamp > this.last_timestamp);
                        if(new_data_index !== -1) {
                            const newMetricData = metricData.slice(new_data_index);
                            this.starlinkMetrics[i].data = [...this.starlinkMetrics[i].data, ...newMetricData];
                            newData = true;
                        } else {
                            break;
                        }
                    }
                } else if (res.data && CountOfMetrics<2) {
                    const metricData = res.data[0].data;
                    const new_data_index = metricData.findIndex(item => item.timestamp > this.last_timestamp);
                    if(new_data_index !== -1) {
                        const newMetricData = metricData.slice(new_data_index);
                        this.starlinkMetrics[0].data = [...this.starlinkMetrics[0].data, ...newMetricData];
                        newData = true;
                    } 
                }
                if(newData === true) {
                    this.processNewAPIData(CountOfMetrics);
                    this.setLastTimeStamp(res.data);
                } else {
                    this.loaded = true;
                }
            })
            .catch(e => {
                this.loaded = true;
                this.errors.push(e);
                console.log("Error(Updating Starlink Data): ", this.errors);
            })
        },

        getStarlinkAPIData(request=null) {
            this.loaded = false;
            if(!this.device.Source.kitSerialNumber) {
                const starlink = JSON.parse(this.device.Source);
                this.device.Source = starlink; 
                this.source = this.device.Source.kitSerialNumber;
            } else {
                this.source = this.device.Source.kitSerialNumber;
            }
            this.setOptions();
            this.$http.get(`site/${this.siteid}/starlink/metrics/${this.source}?${this.metricParam}=${this.metrics}&start=${this.last_timestamp}&end=${this.end}`).then((res) => {
                this.stopTimer();
                this.starlinkMetrics = res.data;
                this.setLastTimeStamp(res.data)
                this.updateGraphData(); //start auto-update timer
                const CountOfMetrics = res.data.length;
                this.processNewAPIData(CountOfMetrics); 
                if(request === 'liveButton') {
                    this.syncDisabled = false;
                    this.liveDisabled = true;
                }
            })
            .catch(err => {
                this.loaded = true;
                this.errors.push(err);
                console.log("Error(Starlink API Data): ", this.errors);                                                                            
            })
        },
        setLastTimeStamp(resData) {
            // console.log('Setting timestamp....', resData)
            if(resData[0].data.length>0) {
                // console.log('if statement TRUE', resData[0].data.length-1);
                let lastIndex = resData[0].data.length-1;
                // console.log("....", resData[0].data[0].timestamp, 'and', resData[0].data[lastIndex].timestamp);
                
                let lastDataPoint = resData[0].data[lastIndex];
                // console.log('last point', lastDataPoint)
                this.last_timestamp = lastDataPoint.timestamp;
            }
        },
        getMetricsFromStorage() {
                let data = JSON.parse(localStorage.getItem(`starlinkMetrics_${this.widgetId}_${this.device.Source.kitSerialNumber}`));
                if(!data) {
                    this.getStarlinkAPIData();
                } else {
                    this.stopTimer();
                    this.loaded = false;
                    this.starlinkMetrics = data;
                    this.setLastTimeStamp(this.starlinkMetrics);
                    this.setOptions();
                    const CountOfMetrics = this.starlinkMetrics.length;
                    this.processNewAPIData(CountOfMetrics, 'fromStorage');
                    this.updateStarlinkMetricData();
                    this.updateGraphData();
                }
        },
        saveToStorage(res) {
            // if(this.timePassedFilter(5)) { 
            //     console.log('dont save, clearing')
            //     this.clearLocalStorage();
            //     return;
            // } 
            if(!this.device.Source.kitSerialNumber) {
                const starlink = JSON.parse(this.device.Source);
                this.device.Source = starlink; 
                this.source = starlink.Source.kitSerialNumber;
            }
            const numberOfItems = localStorage.length;
            if(numberOfItems<16) {
                localStorage.setItem(`starlinkMetrics_${this.widgetId}_${this.device.Source.kitSerialNumber}`, JSON.stringify(res))
            }
        },
        updateGraphData() {
            this.graphDataTimer = setInterval(function () {
                this.updateStarlinkMetricData();
            }.bind(this), 300000); 
        },
        stopTimer(){
            clearInterval(this.graphDataTimer);
        },
        setOptions() {
            const caseOptions = this.setDygraphOptions();

            this.dyOpts = {
                labels: caseOptions.labels,
                labelsKMG2: false,
                labelsSeparateLines: false,
                legend: 'always',
                fillGraph:
                    this.properties?.showmingraph || this.properties?.showmaxgraph ? false : true,
                strokeWidth: 1.5,
                fillAlpha: 0.06,
                axes: caseOptions.axes,
                series: caseOptions.series,
                includeZero: true,
                // connectSeparatedPoints: true,
            }
        },
        async consolidateData(CountOfMetrics, starlinkData) { //no need to wait on this process
            this.consolidatedData = starlinkData; //creates object keys for below use.
            if(starlinkData[0].data.length > 300) {
                for(let i=0; i < CountOfMetrics; i++) {
                    //consolidate data to 5 minute intervals in the last 24 hours and save to local storage.
                    let allData = starlinkData[i].data; 
                    const dataLength = allData.length;
                    let tempConsArray = [];
                    for(let j=0; j < dataLength; j+=5) {
                        tempConsArray.push(allData[j]); //add every 5th point which is ~5 minute intervals
                    }
                    this.consolidatedData[i].data = tempConsArray.slice(-290);
                    this.saveToStorage(this.consolidatedData);
                }
            } else {
                this.saveToStorage(starlinkData);
            }
        },
        processNewAPIData(metricsCount, storage=null) {
            const localDate = new Date();
            const offset = localDate.getTimezoneOffset() * 60000;

            if(storage !== 'fromStorage') {
                // console.log("consolidating data in storage")
                // this.consolidateData(metricsCount, this.starlinkMetrics);
            }
            let metrics = {};
            let formattedData = [];
            metrics.s1 = this.starlinkMetrics[0].data;
            let seriesLength = metrics.s1.length;
            // const startIndex = Math.max(0, seriesLength - 1800);
            const startIndex = 0;
            if(metricsCount>1) {
                //add loop if graph may ever contain more than 2, currently, it never should.
                metrics.s2 = this.starlinkMetrics[1].data;
                let series2Length = metrics.s2.length;
                //if series 2 is shorter, make them equal by dropping the oldest points from series 1.
                if(seriesLength > series2Length) {
                    const dropCount = seriesLength - series2Length;
                    seriesLength = seriesLength - dropCount;
                    metrics.s1 = metrics.s1.slice(dropCount);
                }

                for(let i=startIndex; i < seriesLength; i++) {
                    if(metrics.s1[i]==undefined || metrics.s1[i]=='undefined') {
                        // console.log("i = ", i);
                    }
                    const date = new Date(metrics.s1[i].timestamp);
                    const adjustedTimestamp= date.getTime() + offset;
                    formattedData.push([new Date(adjustedTimestamp), metrics.s1[i].value, metrics.s2[i].value])
                }
            } else {
                for(let i=startIndex; i < seriesLength; i++) {
                    const date = new Date(metrics.s1[i].timestamp);
                    const adjustedTimestamp= date.getTime() + offset;
                    formattedData.push([new Date(adjustedTimestamp), metrics.s1[i].value])
                }
            }

            this.dyData = formattedData;
            this.loaded = true;
        },
        setDygraphOptions() {
            switch(this.widgetId) {
                case 53:
                    this.metricParam = 'metrics';
                    this.metrics = 'downlinkThroughput,uplinkThroughput';
                    this.graphName = 'Traffic';
                    let options53 = {
                        labels: ['Timestamp', 'Down', 'Up'],
                        axes: {
                            y: {
                                valueFormatter: function (y) {
                                    return KMG(y *1000 *1000) + 'bps';
                                },
                                axisLabelFormatter: function (y) {
                                    return KMG(y * 1000*1000);
                                },
                            },
                            y2: {
                                valueFormatter: function (y) {
                                    return KMG(y * 1000 * 1000) + 'bps';
                                },
                                axisLabelFormatter: function (y) {
                                    return KMG(y * 1000*1000);
                                },
                            }
                        },
                        series: {
                            Down: {
                                axis: 'y'
                            },
                            Up: {
                                axis: 'y2'
                            }
                        },
                    }
                    return options53
                case 54:
                    this.metrics = 'pingLatency';
                    this.graphName = 'Ping Latency';
                    let options54 = {
                        labels: ['Timestamp', 'Ping Latency'],
                        axes: {
                            y: {
                                valueFormatter: function (v) {
                                        return v + 'ms';
                                },
                                axisLabelFormatter: function (y) {
                                    return y+'ms';
                                },
                            },
                        },
                        series: {
                            'Ping Latency': {
                                color: '#54a5ff',
							},
                        },
                    }
                    return options54
                case 55:
                    this.metrics = 'pingDropRate';
                    this.graphName = 'Ping Drop Rate';
                    let options55 = {
                        labels: ['Timestamp', 'Ping Drop Rate'],
                        axes: { 
                            y: {
                                valueFormatter: function (v) {
                                    return v + '%';
                                },
                                axisLabelFormatter: function (y) {
                                    return y.toFixed(2)+'%';
                                },
                            },    
                        },
                        series: {
							'Ping Drop Rate': {
								color: '#5500ff',
							},
						},
                    }
                    return options55
                case 56:
                    this.metrics = 'signalQuality';
                    this.graphName = 'Signal Quality';
                    let options56 = {
                        labels: ['Timestamp', 'Signal Quality'],
                        axes: {
                            y: {
                                valueFormatter: function (v) {
                                    return v*100 + '%';
                                },
                                axisLabelFormatter: function (y) {
                                    return Math.round(y*100)+'%';
                                },
                            },
                        },
                        series: {
							'Signal Quality': {
								color: '#54a5ff',
							},
						},
                    }
                    return options56
                case 57:
                    this.metrics = 'obstruction';
                    this.graphName = 'Obstruction';
                    let options57 = {
                        labels: ['Timestamp', 'Obstruction'],
                        axes: {
                            y: {
                                valueFormatter: function (v) {
                                    return v + '%';
                                },
                                axisLabelFormatter: function (y) {
                                    return y.toFixed(2)+'%';
                                },
                            },
                        },
                        series: {
							'Obstruction': {
								color: '#ff0000',
							},
						},
                    }
                    return options57
                case 59:
                    this.metrics = 'uptime';
                    this.graphName = 'Uptime';
                    let options59 = {
                        labels: ['Timestamp', 'Uptime'],
                        axes: {
                            y: {
                                valueFormatter: function (v) {
                                    return formatTime(v);
                                },
                                axisLabelFormatter: function (y) {
                                    return Math.round(y / 3600 * 10) / 10 +' h';
                                },
                            },
                        },
                        series: {
                            'Uptime': {
                                color: '#54a5ff',
							},
                        },
                    }
                    return options59
            }

        },
        handleVisibilityChange() {
            if (document.visibilityState === "visible") {
                // The page is in focus and visible to the user.
                // console.log("Page is in focus.");
            } else if (document.visibilityState === "hidden") {
                // The page is in the background or hidden.
                // console.log("Page is in the background.");
                this.clearLocalStorage();
            }
        },
        clearLocalStorage() {
            // may take an event if registering a listener console.log('EVENT:', event)
            // if (!this.isRefreshing) {
                // const itemsToKeep = ['user', 'userAuth', 'pageLoadTime', 'store'];

                // Get all keys from local storage
                const keys = Object.keys(localStorage);

                // Remove items that are not in the 'itemsToKeep' array
                // keys.forEach(key => {
                //     if (!itemsToKeep.includes(key)) {
                //         localStorage.removeItem(key);
                //     }
                // })
                keys.forEach(key => {
                    if (key.startsWith('starlink')) {
                        localStorage.removeItem(key);
                    }
                })
                // localStorage.clear();
                // Or perform any other cleanup tasks here
            // }
        // The browser-specific returnValue is necessary to trigger the confirmation dialog
        // event.returnValue = '';
        },
        getElapsedTime() {
            const currentTime = Date.now();
            // console.log('start time', this.pageLoadTime);
            // console.log('now', currentTime);
            const elapsedTime = currentTime - this.pageLoadTime;
            // console.log("elapsed", elapsedTime);
            return elapsedTime;
        },
        timePassedFilter(minutes) {
            const timeInMs = minutes * 60 * 1000;
            // console.log('5min in ms', timeInMs)
            const time = this.getElapsedTime()
            console.log("true?", time >= timeInMs)
            return time > timeInMs;
        }
    },
    watch: {
        DateRange: {
            handler(nVal, oVal) {
                console.log("date change", nVal, oVal)
                this.syncDisabled = false
            },
            deep:true,
        }
	},
    created() {
        // console.log("*** Created ***");
        // //converting to object stored as string from MySQL  
        // if(!this.device.Source && !this.device.Source.kitSerialNumber) {
        //     const starlink = JSON.parse(this.device.Source);
        //     this.device.Source = starlink; 
        // }
        if(!this.device.Source.kitSerialNumber) {
                const starlink = JSON.parse(this.device.Source);
                this.device.Source = starlink; 
                this.source = this.device.Source.kitSerialNumber;
            } else {
                this.source = this.device.Source.kitSerialNumber;
            }
    },
    beforeMount() {
        // console.log("*** Before Mount ***");
        // const storedPageLoadTime = localStorage.getItem('pageLoadTime');
        // if (storedPageLoadTime) {
        //     console.log("page load time true")
        //     this.pageLoadTime = parseInt(storedPageLoadTime);
        //      //if 5minutes has passed (slightly less: see method)
        //      const timePassed = this.timePassedFilter(5);
        //     if(timePassed) { 
        //         console.log('****** time passed of 5 minutes true, clear storage ******')
        //         this.pageLoadTime = Date.now();
        //         console.log('updated time:', this.pageLoadTime)
        //         localStorage.setItem('pageLoadTime', this.pageLoadTime.toString());
        //         this.clearLocalStorage();
        //     } 
        // } else {
        // // If not stored, it means the page is newly loaded, so set the pageLoadTime
        //     console.log("pageload time not stored, setting time")
        //     this.pageLoadTime = Date.now();
        //     localStorage.setItem('pageLoadTime', this.pageLoadTime.toString());
        // }

        this.getMetricsFromStorage(); 
    },
    mounted() {
        // console.log("*** Mounted ***");
        document.addEventListener("visibilitychange", this.handleVisibilityChange);
        this.handleVisibilityChange();
        // window.addEventListener('beforeunload', this.handleBeforeUnload);
        // window.addEventListener('unload', this.handleUnload);
        // window.addEventListener('beforeunload', this.detectRefresh);        
    },

    beforeUpdate() {
        // console.log("*** UPDATE before ***");
    },
    updated() {
        // console.log("*** Updated ***");
        // console.log("UPDated...")
    },
    beforeUnmount() {
        // console.log("*** before UNmount ***");
        // console.log("Before Unmount !")
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    },
    unmounted() {
        // console.log(" this is UNMounted")
    },
    beforeDestroy() {
        // console.log("*** before DESTROY ***");
        // console.log("Before DESTROY!")
        this.stopTimer();
        // window.removeEventListener('beforeunload', this.handleBeforeUnload);
        // window.removeEventListener('unload', this.handleUnload);
        // window.removeEventListener('beforeunload', this.detectRefresh);
    }


};
</script>

<style scoped>
.disabled-button {
  cursor: not-allowed;
}

.fading-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
}
</style>